<template>
    <v-row no-gutters>
        <v-col cols="12">
            <v-row justify="center" class="py-5">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                    <v-card elevation="2" class="my-6">
                        <v-app-bar color="#3F51B5" dark flat dense>
                            <v-tooltip bottom>
                                <template #activator="{ on, attrs }">
                                    <v-btn icon @click="$router.go(-1)" v-bind="attrs" v-on="on">
                                        <font-awesome-icon :icon="['fas', 'chevron-left']" style="font-size: 20px;" fixed-width/>
                                    </v-btn>
                                </template>
                                Back
                            </v-tooltip>
                            <v-app-bar-title>My profile settings</v-app-bar-title>
                            <!-- <v-spacer/> -->
                            <!-- <v-btn icon @click="refreshUserProfile">
                                <font-awesome-icon :icon="['far', 'sync-alt']" fixed-width/>
                            </v-btn> -->
                        </v-app-bar>
                        <v-card-text>
                            <p class="mb-0 pb-0">
                                <span>{{ displayName }}</span><br/>
                                <span>{{ email }}</span>
                            </p>
                            <!-- <p class="text-overline mb-0 mt-8">Other</p>
                            <p class="mb-0 pb-0">
                                <router-link :to="{ name: 'link-to-somewhere-else' }">Something else...</router-link>
                            </p> -->
                        </v-card-text>
                    </v-card>
                    <v-expansion-panels class="mt-8 mb-6">
                        <v-expansion-panel>
                            <v-expansion-panel-header>
                                <span class="blue--text text--darken-2"><font-awesome-icon :icon="['fas', 'shield-alt']" class="mr-1"></font-awesome-icon> Security</span>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <p class="mb-0 pb-0">
                                    <router-link :to="{ name: 'profile-settings-session' }">Session info</router-link>
                                </p>
                                <p class="mb-0 pb-0">
                                    <router-link :to="{ name: 'profile-settings-authz' }">Roles and permits</router-link>
                                </p>
                                <p class="mb-0 pb-0">
                                    <router-link :to="{ name: 'profile-settings-authn-loginfront' }">Login settings</router-link>
                                </p>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                    </v-expansion-panels>
                    <v-expansion-panels class="mt-8 mb-6">
                        <v-expansion-panel>
                            <v-expansion-panel-header>
                                <span class="red--text text--darken-2"><font-awesome-icon :icon="['fas', 'exclamation-triangle']" class="mr-1"></font-awesome-icon> Delete profile</span>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <p class="mb-0 pb-0">
                                    <router-link :to="{ name: 'profile-delete' }">How to delete my profile and related data</router-link>
                                </p>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                    </v-expansion-panels>
                </v-col>
            </v-row>

            <!-- <v-row justify="center" class="py-5">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                    <v-card elevation="4" class="pa-5">
                        <v-card-text class="text-h6 text-center pa-0 pb-3">Name</v-card-text>
                        <v-divider class="mx-5 mb-5"></v-divider>
                        <v-row justify="center" class="pb-3">
                            <v-col cols="12">
                                <div class="font-weight-light text-body-1 text-center mx-5">
                                    <p>We will use this name to address you on our website and when we communicate by email, telephone, or letters.</p>
                                    <p>For example, "Sparky" or "Doctor Who"</p>
                                </div>
                            </v-col>
                        </v-row>
                        <v-row justify="center" class="mx-5">
                            <v-text-field
                                outlined
                                v-model=editableDisplayName
                                color="blue"
                                label="Your nickname, first name, or title"
                                :rules="nameRules"
                            ></v-text-field>
                        </v-row>
                        <v-row justify="center">
                            <v-btn elevation="4" class="blue white--text" @click="editDisplayName" :disabled="!isIdentityNameComplete">
                                <font-awesome-icon icon="check" fixed-width/>
                                Save
                            </v-btn>
                        </v-row>
                    </v-card>
                </v-col>
            </v-row> -->
            <!-- <p>If you change this address we will send a verification email and you must click on the link in the email to confirm the change.</p> -->
            <!-- <v-row justify="center" class="py-5">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                    <v-card elevation="4" class="pa-5">
                        <v-card-text class="text-h6 text-center pa-0 pb-3">Email</v-card-text>
                        <v-divider class="mx-5 mb-5"></v-divider>
                        <v-row justify="center" class="pb-3">
                            <v-col cols="12">
                                <div class="font-weight-light text-body-1 text-center mx-5">
                                    <p>We will use this email address to send you electronic messages.</p>
                                </div>
                            </v-col>
                        </v-row>
                        <v-row justify="center" class="mx-5">
                            <v-text-field
                                outlined
                                v-model=editableEmail
                                color="blue"
                                label="Your email address"
                                :rules="emailRules"
                                readonly
                                disabled
                            ></v-text-field>
                        </v-row>
                    </v-card>
                </v-col>
            </v-row> -->
            <!-- <v-row justify="center" class="py-5">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                    <p class="text-overline mb-0">Other</p>
                    <p>
                        <router-link :to="{ path: '/user/delete', query: { acct: this.$route.query.acct } }">How to delete this profile</router-link>
                    </p>
                </v-col>
            </v-row> -->
        </v-col>
    </v-row>
</template>

<script>
import { mapState /* , mapGetters */ } from 'vuex';
import { isValidName, isValidEmail, isValidPhone } from '@/sdk/input';

export default {
    data: () => ({
        dialogDeleteAccount: false,
        // nameRules: [
        //     (v) => (typeof v === 'string' && v.length >= 1) || 'Must not be empty',
        // ],
        nameRules: [
            (v) => isValidName(v) || 'Enter your name',
        ],
        emailRules: [
            (v) => !v || isValidEmail(v) || 'Enter your email address',
        ],
        phoneRules: [
            (v) => !v || isValidPhone(v) || 'Enter your phone number',
        ],
        // snackbarIdentityNameUpdated: false,
        // snackbarIdentityEmailUpdated: false,
        dialogEditDisplayName: false,
        editableDisplayName: null,
        editableEmail: null,
    }),
    computed: {
        ...mapState({
            isAuthenticatedReady: (state) => state.isReady,
            user: (state) => state.user,
            session: (state) => state.session,
        }),
        displayName() {
            if (typeof this.user.display_name === 'string' && this.user.display_name.trim().length > 0) {
                return this.user.display_name;
            }
            return '(unknown)';
        },
        email() {
            if (typeof this.user.email === 'string' && this.user.email.trim().length > 0) {
                return this.user.email;
            }
            return '(unknown)';
        },
        // name: {
        //     get() {
        //         if (this.user.name) {
        //             return this.user.name;
        //         }
        //         return '';
        //     },
        //     set(value) {
        //         this.$store.dispatch('editUser', { name: value });
        //     },
        // },
        // email: {
        //     get() {
        //         if (this.user.email) {
        //             return this.user.email;
        //         }
        //         return '';
        //     },
        //     set(value) {
        //         // TODO: need email verification first ... let server set it after user clicks link ... server should reject editUser { email } , it should make available a workflow for changing email that a client can initiate
        //         this.$store.dispatch('editUser', { email: value });
        //     },
        // },
        isEditDisplayNameFormComplete() {
            return this.user
            && this.editableDisplayName
            && this.editableDisplayName !== this.user.display_name;
        },
        // isIdentityNameComplete() {
        //     return this.user
        //     && this.editableDisplayName
        //     && this.editableDisplayName !== this.user.name;
        // },
        // isIdentityEmailComplete() {
        //     return this.user
        //     && this.editableEmail
        //     && this.editableEmail !== this.user.email;
        // },
    },
    watch: {
        isAuthenticatedReady(value, oldValue) {
            if (value && !oldValue) {
                this.init();
            }
        },
        dialogEditDisplayName(display) {
            if (display) {
                this.editableDisplayName = this.user.display_name;
                setTimeout(() => { this.$nextTick(() => this.$refs.displayNameInput.focus()); }, 1);
            }
        },
        // email(val) {
        //     this.editableEmail = val;
        // },
        dialogDeleteAccount(val) {
            if (!val) {
                this.closeDialogDeleteAccount();
            }
        },
    },
    methods: {
        init() {
            console.log('Preferences.vue: init');
        },
        async editDisplayName() {
            // try {
            //     this.$store.commit('loading', { editDisplayName: true });
            const isEdited = await this.$store.dispatch('editCurrentUser', { display_name: this.editableDisplayName });
            // this.name = this.editableDisplayName;
            // this.snackbarIdentityNameUpdated = true;
            if (isEdited) {
                await this.$store.dispatch('loadUser');
                this.dialogEditDisplayName = false;
                this.$bus.$emit('snackbar', { type: 'success', message: 'Updated name' });
            } else {
                this.$bus.$emit('snackbar', { type: 'error', message: 'Failed to update name' });
            }
            // } catch (err) {
            //     console.error('editDisplayName failed', err);
            //     this.$bus.$emit('snackbar', { type: 'error', message: 'Failed to update name' });
            // } finally {
            //     this.$store.commit('loading', { editDisplayName: false });
            // }
        },
        // editEmail() {
        //     this.email = this.editableEmail;
        //     this.snackbarIdentityEmailUpdated = true;
        // },
        async deleteAccount() {
            this.$store.commit('loading', { deleteAccount: true });
            const response = await this.$client.user(this.$store.state.session.userId).user.delete();
            if (response.isDeleted) {
                await this.$store.dispatch('logout');
                this.$router.push('/');
                console.log('account.vue: deleteAccount success: %o', response);
            } else {
                console.log('account.vue: deleteAccount failed: %o', response);
                // this.snackbarAccountDeleteFailed = true;
            }
            this.closeDialogDeleteAccount();
            this.$store.commit('loading', { deleteAccount: false });
        },
        closeDialogDeleteAccount() {
            this.dialogDeleteAccount = false;
        },
        async refreshUserProfile() {
            this.$store.commit('loading', { refreshUserProfile: true });
            const response = await this.$client.user(this.$store.state.session.userId).user.check({ item: 'profile' });
            console.log(`refreshUserProfile edited? ${response.isEdited}`);
            if (response.isEdited) {
                await this.$store.dispatch('refresh');
                this.$bus.$emit('snackbar', { type: 'success', headline: 'Refreshed profile' });
            } else {
                this.$bus.$emit('snackbar', { type: 'info', headline: 'No changes to profile' });
            }
            this.$store.commit('loading', { refreshUserProfile: false });
        },
    },
    mounted() {
    },
};
</script>
